import RequestDemoButton from "app/components/buttons/request-demo";
import smallBanner from "app/images/banners/tap-into-ccommerce-sm.png";
import banner from "app/images/banners/tap-into-ccommerce.png";

const TapIntoCCommerceBanner = () => (
  <div className="relative">
    <div className="block sm:hidden">
      <img alt="Tap into cCommerce" src={smallBanner} loading="lazy" />
    </div>
    <div className="hidden sm:block">
      <img alt="Tap into cCommerce" src={banner} loading="lazy" />
    </div>
    <RequestDemoButton
      id="cta_tapIntoCCommerceBanner_requestDemo"
      additionalClassNames="yellow-green-cyan-btn absolute bottom-[15%] left-1/2 h-10 py-2 w-full max-w-[250px] -translate-x-1/2 rounded-full sm:bottom-0 sm:left-auto sm:top-1/2 sm:right-8 sm:w-auto sm:max-w-none sm:-translate-y-1/2 sm:translate-x-0 md:right-12 lg:right-16 xl:right-20"
      textClassNames="sm:px-8 md:px-12 lg:px-16 xl:px-20"
    />
  </div>
);

export default TapIntoCCommerceBanner;
